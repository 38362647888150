// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('jquery')
require('jquery-ui')
require("@rails/ujs").start()
/**
 * Both React-Router and Turbolinks try to control back/forward buttons, 
 * so I'm disabling turbolinks here in favor of using React-Router to 
 * handle routing within the React app
 */
// require("turbolinks").start()
require("@rails/activestorage").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'bootstrap'
import '@/stylesheets/application'

import Rails from "@rails/ujs";
window.Rails = Rails;
